/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable max-len */
import { generateThumborUrl } from '@helpers/image';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import NextImage from 'next/image';
import Show from '@common/Show';
import Head from 'next/head';
import useMediaQuery from '@hook/useMediaQuery';

const Container = ({
    children, enable, className, style,
}) =>
    (enable ? (
        <span className={className} style={style}>
            {children}
        </span>
    ) : (
        <>{children}</>
    ));

const CustomImage = ({
    src,
    width = 0,
    height = 0,
    // srcMobile = '',
    widthMobile = 0,
    heightMobile = 0,
    magezon,
    useContainer = true,
    classContainer = '',
    styleContainer: initStyleContainer = {},
    styleImage: initStyleImage = {},
    className = '',
    alt = 'Image',
    quality = 80,
    storeConfig = {},
    slickBanner = false,
    preload = false,
    style = '',
    retina = false,
    tabletImageUrl,
    landscapePhoneImageUrl,
    portraitPhoneImageUrl,
    isThumborNoResize = false,
}) => {
    const enable = storeConfig && storeConfig.pwa && storeConfig.pwa.thumbor_enable;
    const useHttpsOrHttp = storeConfig && storeConfig.pwa && storeConfig.pwa.thumbor_https_http;
    const thumborUrl = storeConfig && storeConfig.pwa && storeConfig.pwa.thumbor_url;

    const { isMobile } = useMediaQuery();

    let w = width;
    let h = height;
    if (w === '' || h === '') {
        w = 0;
        h = 0;
    } else if (retina) {
        w = width * 2;
        h = height * 2;
    }

    const getThumborUrl = (image_src, w_mobile, h_mobile) => (image_src && (image_src?.toLowerCase().indexOf('http://') === 0 || image_src?.toLowerCase().indexOf('https://') === 0)
        ? generateThumborUrl(image_src, isThumborNoResize ? 0 : w_mobile || w, isThumborNoResize ? 0 : h_mobile || h, enable, useHttpsOrHttp, thumborUrl, quality)
        : image_src);

    const optimizedUrl = getThumborUrl(src);

    const [imageUrl, setImageUrl] = useState(optimizedUrl);

    const handleErrorImage = (e) => {
        e.stopPropagation();
        setImageUrl('./assets/img/fallback-logo.png');
    };

    useEffect(() => {
        if (optimizedUrl !== imageUrl) {
            setImageUrl(optimizedUrl);
        }
    }, [optimizedUrl]);

    let styleContainer = {};
    let styleImage = style;
    if (useContainer) {
        styleContainer = {
            width: '100%',
            position: 'relative',
            paddingTop: `${(height / width) * 100}%`,
            overflow: 'hidden',
            display: 'block',
            ...initStyleContainer,
        };
        styleImage = {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            objectFit: 'cover',
            ...initStyleImage,
        };
    }

    if (magezon) {
        styleContainer = {
            width: 'fit-content',
            overflow: 'hidden',
            display: 'block',
        };
        styleImage = {
            maxWidth: '100%',
            maxHeight: '100%',
            height: 'auto',
        };
    }

    if (slickBanner) {
        styleContainer = {};
        styleImage = {};
    }

    let nextImageProps = {};
    // handle when image not set width & height / want to use source image dimension
    if (!(width || height)) {
        styleContainer = { ...styleContainer, marginLeft: 'auto', marginRight: 'auto' };
        styleImage = { width: '100%', height: 'auto' };
        nextImageProps = {
            sizes: '100vw',
        };
    }

    // start - magezon only
    // default min-width
    let tabletMinWidth = 769;
    let landscapePhoneMinWidth = 577;
    const portraitPhoneMinWidth = 0;

    // tablet min-width condition
    if (!landscapePhoneImageUrl) {
        tabletMinWidth = landscapePhoneMinWidth;
        if (!portraitPhoneImageUrl) {
            tabletMinWidth = portraitPhoneMinWidth;
        }
    }
    // landscape phone min-width condition
    if (!portraitPhoneImageUrl) {
        landscapePhoneMinWidth = 0;
    }
    // end - magezon only

    return (
        <>
            {/* Preload mobile image */}
            {preload && (portraitPhoneImageUrl || landscapePhoneImageUrl || tabletImageUrl) ? (
                <Head>
                    {landscapePhoneImageUrl ? (
                        <link
                            rel="preload"
                            href={getThumborUrl(landscapePhoneImageUrl, widthMobile, heightMobile)}
                            as="image"
                            media={`(min-width: ${landscapePhoneMinWidth}px) and (max-width: 768px)`}
                            fetchpriority="high"
                        />
                    ) : null}
                    {portraitPhoneImageUrl ? (
                        <link
                            rel="preload"
                            href={getThumborUrl(portraitPhoneImageUrl, widthMobile, heightMobile)}
                            as="image"
                            media={`(min-width: ${portraitPhoneMinWidth}px) and (max-width: 576px)`}
                            fetchpriority="high"
                        />
                    ) : null}
                    {tabletImageUrl ? (
                        <link
                            rel="preload"
                            href={getThumborUrl(tabletImageUrl)}
                            as="image"
                            media={`(min-width: ${tabletMinWidth}px) and (max-width: 1024px)`}
                            fetchpriority="high"
                        />
                    ) : null}
                </Head>
            ) : null}
            <Container enable={useContainer} className={classContainer} style={styleContainer}>
                <picture>
                    {/** start - magezon image only */}
                    <Show when={tabletImageUrl}>
                        <source media={`(min-width: ${tabletMinWidth}px) and (max-width: 1024px)`} srcSet={getThumborUrl(tabletImageUrl)} />
                    </Show>
                    <Show when={landscapePhoneImageUrl}>
                        <source
                            media={`(min-width: ${landscapePhoneMinWidth}px) and (max-width: 768px)`}
                            srcSet={getThumborUrl(landscapePhoneImageUrl, widthMobile, heightMobile)}
                        />
                    </Show>
                    <Show when={portraitPhoneImageUrl}>
                        <source media={`(min-width: ${portraitPhoneMinWidth}px) and (max-width: 576px)`} srcSet={getThumborUrl(portraitPhoneImageUrl, widthMobile, heightMobile)} />
                    </Show>
                    {/** end - magezon image only */}

                    <NextImage
                        src={imageUrl}
                        style={styleImage}
                        className={cx('img', className)}
                        alt={alt}
                        width={width || 0}
                        height={height || 0}
                        unoptimized
                        priority={isMobile && landscapePhoneImageUrl && portraitPhoneImageUrl ? false : preload}
                        quality={quality}
                        onError={handleErrorImage}
                        {...nextImageProps}
                    />
                </picture>
            </Container>
        </>
    );
};

export default CustomImage;
